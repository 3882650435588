import _ from "lodash";
import React, { FC, useState } from "react";
import XLSX from "xlsx";
import { Icon, InputSelect } from "../../../components";
import { PopupWraper } from "../../../components/popup";
import {
  TableFilterInputText,
  TableFilterRangeTimeInput,
} from "../../../components/table-filter-inputs";
import { getEnv } from "../../../configs";
import {
  Button,
  CreateAlert,
  InputWraper,
  NumberUtils,
  Table,
  onError,
  useForm,
} from "../../../modules";
import { AdminService } from "../../../services/admin";
import { TransferMainWallet } from "../transfer-main-wallet";
import { withFranchiseWraper } from "../wraper";
import { translate } from "../../../languages";

export const PageFranchiseTable = withFranchiseWraper(() => {
  // const user = useSelector((state) => state.user);
  const [currentData, setCurrentData] = useState(null as any);
  const [currentUserSetLevel, setCurrentUserSetLevel] = useState<any>();
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [isShowTable, setIsShowTable] = useState<boolean>(true);
  const [params, setParams] = useState<any>();
  const [openTransfer, setOpenTransfer] = useState<any>(null);
  const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());
  const [disableExport, setDisableExport] = useState<boolean>(true);


  // const startOfMonth = moment().subtract(1, "months").startOf("month");
  // const endOfDay = moment().endOf("day");

  let renderRank = (value: any) => {
    if (+value < 0) return 0;
    if (+value > 7) return 7;
    return +value;
  };

  return (
    <div className="PageFranchiseTable">
      <Button
        disabled={disableExport || !currentData}
        label="Export to Excel"
        buttonType="primary"
        
        className="mb15"
        onClick={async () => {
          // if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
          // if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
          const response = await AdminService.getListAffilateTable({
            ...params,
            pageSize: 1000000000,
            page: 1,
          });
          const data = [
            [
              "Email",
              "Nick Name",
              "ID",
              "Total Volume",
              "Profit",
              "GT Balance",
              "KT-GT Balance",
              "GTM Balance",
              "GTV Balance",
              "GTC Balance",
              "TAX  Balance",
              "Total F1",
              "Total Member 7 Rank",
              "Rank"
            ],
            ...response.data.map((item: any) => {
              // let ParseIP = item?.ip ? `IP: ${item?.ip}` : `IP: ?`;
              let row: any[] = [];
              // row.push(item?.firstName + "\n" + item?.email + "\n" + ParseIP);
              row.push(item?.email);
              row.push(item?.firstName);
              row.push(item?.id);
              row.push(
                NumberUtils.toDecimal(
                  item?.volume,
                  +getEnv("NUMBER_DECIMAL_DISPLAY")
                )
              );
              row.push(
                NumberUtils.toDecimal(
                  item?.profitVolume,
                  +getEnv("NUMBER_DECIMAL_DISPLAY")
                )
              );
              row.push(
                NumberUtils.toDecimal(
                  item?.balanceUsdt,
                  +getEnv("NUMBER_DECIMAL_DISPLAY")
                )
              );

              row.push(
                NumberUtils.toDecimal(
                  item?.balanceKtgt,
                  +getEnv("NUMBER_DECIMAL_DISPLAY")
                )
              );

              row.push(
                NumberUtils.toDecimal(
                  item?.balanceGtm,
                  +getEnv("NUMBER_DECIMAL_DISPLAY")
                )
              );

              row.push(
                NumberUtils.toDecimal(
                  item?.balanceGtv,
                  +getEnv("NUMBER_DECIMAL_DISPLAY")
                )
              );

              row.push(
                NumberUtils.toDecimal(
                  item?.balanceGtc,
                  +getEnv("NUMBER_DECIMAL_DISPLAY")
                )
              );

              row.push(
                NumberUtils.toDecimal(
                  item?.balanceGttax,
                  +getEnv("NUMBER_DECIMAL_DISPLAY")
                )
              );
              
              row.push(item?.numberOfF1);
              row.push(item?.totalMemberSevenLevel);
              row.push(item?.rank);
              

              return row;
            }),
          ];

          const ws = XLSX.utils.aoa_to_sheet(data);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

          const now = new Date();
          XLSX.writeFile(
            wb,
            `Franchise ${now.toLocaleDateString().replace(/\//g, "-")} ${now
              .toLocaleTimeString()
              .replace(/:/g, "-")}.xlsx`,
            {
              type: "binary",
            }
          );
        }}
      />

      {isShowTable && (
        <Table
          hasOrderColumn
          className="overload"
          forceUpdateTable={forceUpdateTable}
          filters={[
            {
              name: "Email",
              key: "searchString",
              input: TableFilterInputText,
            },
            {
              name: "Date",
              key: "created",
              input: (e) => (
                <TableFilterRangeTimeInput
                  {...e}
                  fromKey="fromDate"
                  toKey="toDate"
                  format={"MM/dd/y"}
                />
              ),
              // defaultValue: {
              //     //fromDate: moment().subtract(1,"months"),
              //     //toDate: moment(),
              //     fromDate: startOfMonth,
              //     toDate: endOfDay,
              // },
            },
          ]}
          structure={[
            {
              name: "Email",
              key: "email",
              render: (item) => {
                return (
                  <div className="user">
                    <span
                      className="user-clickable"
                      // onClick={() => {
                      //   setCurrentUserSetLevel(item);
                      //   setIsShowPopup(true);
                      // }}
                    >
                      {item.firstName} <br />
                      {item.email} <br />
                    </span>
                    {/* <span className="ip">IP: {item?.ip ?? "?"}</span> */}
                  </div>
                );
              },
              sort: {},
            },
            {
              name: translate("id"),
              key: "id",
              style: { minWidth: "100px" },
            },
            {
              name: "Total Volume",
              key: "volume",
              render: (item) => {
                const value = +item.volume;
                return value
                  ? NumberUtils.toFormatNumber(
                      +item.volume,
                      +getEnv("NUMBER_DECIMAL_DISPLAY")
                    )
                  : "0.00000000";
              },
              sort: {},
            },
            {
              name: "Profit",
              key: "profitVolume",
              render: (item) => {
                const value = +item.profitVolume;
                return value ? (
                  <span
                    className={
                      item?.profitVolume === 0
                        ? ""
                        : item?.profitVolume > 0
                        ? "profit-volume-green"
                        : "profit-volume-red"
                    }
                  >
                    {NumberUtils.toFormatNumber(
                      +item?.profitVolume,
                      +getEnv("NUMBER_DECIMAL_DISPLAY")
                    )}
                  </span>
                ) : (
                  <span className="profit-volume-green">0.00000000</span>
                );
              },
              sort: {},
            },
            {
              name: "Commission",
              key: "totalCommissionUsdt",
              render: (item) => {
                return (
                  <span>
                    {NumberUtils.toFormatNumber(
                      +item?.totalCommissionUsdt,
                      +getEnv("NUMBER_DECIMAL_DISPLAY")
                    )}
                  </span>
                );
              },
              sort: {},
            },
            // {
            //     name: "Won volume",
            //     key: "wonVolume",
            //     className: "textSuccess",
            //     render: (item) => {
            //         return `$${(+item.wonVolume).toLocaleString(
            //             getLocaleKey(),
            //             {
            //                 maximumFractionDigits: 2,
            //                 minimumFractionDigits: 2,
            //             }
            //         )}`;
            //     },
            //     sort: {},
            // },
            // {
            //     name: "Lose volume",
            //     key: "lostVolume",
            //     className: "textDanger",
            //     render: (item) => {
            //         const value = +item.lostVolume;
            //         return value
            //             ? `$${(+item.lostVolume).toLocaleString(
            //                   getLocaleKey(),
            //                   {
            //                       maximumFractionDigits: 2,
            //                       minimumFractionDigits: 2,
            //                   }
            //               )}`
            //             : "$0.00";
            //     },
            //     sort: {},
            // },
            // {
            //     name: "Draw volume",
            //     key: "drawVolume",
            //     className: "textWarning",
            //     render: (item) => {
            //         const value = +item.drawVolume;
            //         return value
            //             ? `$${(+item.drawVolume).toLocaleString(
            //                   getLocaleKey(),
            //                   {
            //                       maximumFractionDigits: 2,
            //                       minimumFractionDigits: 2,
            //                   }
            //               )}`
            //             : "$0.00";
            //     },
            //     sort: {},
            // },
            // {
            //   name: "Deposit",
            //   render: (item) => {
            //     return (
            //       <span>
            //         ETH:{" "}
            //         {(+item.depositEth).toLocaleString(getLocaleKey(), {
            //           maximumFractionDigits: 8,
            //           minimumFractionDigits: 8
            //         })}{" "}
            //         <br />
            //         USDT:{" "}
            //         {(+item.depositUsdt).toLocaleString(getLocaleKey(), {
            //           maximumFractionDigits: 8,
            //           minimumFractionDigits: 8
            //         })}
            //       </span>
            //     );
            //   },
            // },
            // {
            //     name: "Withdraw",
            //     render: (item) => {
            //         return (
            //             <span>
            //                 USDT:{" "}
            //                 {NumberUtils.toFormatNumber(+item?.withdrawUsdt, 4)}
            //             </span>
            //         );
            //     },
            // },
            {
              name: "GT Balance",
              key: "balanceUsdt",
              render: (item) => {
                return (
                  <span>
                    {NumberUtils.toFormatNumber(
                      +item?.balanceUsdt,
                      +getEnv("NUMBER_DECIMAL_DISPLAY")
                    )}
                  </span>
                );
              },
              sort: {},
            },
            // {
            //   name: "BUT Balance",
            //   key: "balanceBut",
            //   render: (item) => {
            //     return (
            //       <span>
            //         {NumberUtils.toFormatNumber(
            //           +item?.balanceBut,
            //           +getEnv("NUMBER_DECIMAL_DISPLAY")
            //         )}
            //       </span>
            //     );
            //   },
            //   sort: {},
            // },
            // {
            //   name: "Trading Commission",
            //   key: "rankCommission",
            //   render: (item) => {
            //     return NumberUtils.toFormatNumber(+item?.rankCommission, +getEnv("NUMBER_DECIMAL_DISPLAY"));
            //   },
            //   sort: {},
            // },
            // {
            //   name: "Update VIP Commission",
            //   key: "directCommission",
            //   render: (item) => {
            //     return NumberUtils.toFormatNumber(+item?.directCommission, +getEnv("NUMBER_DECIMAL_DISPLAY"));
            //   },
            //   sort: {},
            // },
            // {
            //   name: "Total F1",
            //   key: "numberOfF1",
            //   sort: {},
            // },
            // {
            //   name: "Total Member 7 Rank",
            //   key: "totalMemberSevenLevel",
            //   sort: {},
            // },
            // {
            //   name: "Cashback 0.2%",
            //   key: "cashBack",
            //   render: (item) => {
            //     return NumberUtils.toFormatNumber(
            //       +item?.cashBack,
            //       +getEnv("NUMBER_DECIMAL_DISPLAY")
            //     );
            //   },
            //   sort: {},
            // },
            // {
            //   name: "Exchange In",
            //   key: "exchangeIn",
            //   render: (item) => {
            //     const value = +item.exchangeIn;
            //     return value
            //       ? NumberUtils.toFormatNumber(+item?.exchangeIn, +getEnv("NUMBER_DECIMAL_DISPLAY"))
            //       : "0.00000000";
            //   },
            //   sort: {},
            // },
            // {
            //   name: "Exchange Out",
            //   key: "exchangeOut",
            //   render: (item) => {
            //     const value = +item.exchangeOut;
            //     return value
            //       ? NumberUtils.toFormatNumber(
            //           +item?.exchangeOut,
            //           +getEnv("NUMBER_DECIMAL_DISPLAY")
            //         )
            //       : "0.00000000";
            //   },
            //   sort: {},
            // },
            {
              name: "Rank",
              key: "rank",
              render: (item) => (
                <div className="level">
                  <img
                    className="level__img"
                    src={`/assets/images/rank/rank_${renderRank(
                      item?.rank
                    )}.png`}
                    alt=""
                  />
                  {item?.rank >= 7 &&
                    item?.title != null &&
                    item?.title != "" && (
                      <div className="extend-rank">
                        <div
                          className="extend-rank__primary"
                          title="GOLD and DIAMOND will enjoy extra % sales than regular level 7, DIAMOND will enjoy % more than GOLD"
                        >
                          <img
                            className="extend-rank__primary__img"
                            src={`/assets/images/${item?.title?.toLowerCase()}_rank.png`}
                            alt=""
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src =
                                "/assets/images/unknown_rank.png";
                            }}
                          />
                          <div
                            className={`extend-rank__primary__label extend-rank__primary__label--${item?.title?.toLowerCase()}`}
                          >
                            {item?.title != null && item?.title != ""
                              ? item?.title.toUpperCase()
                              : "UNKNOWN"}
                          </div>
                        </div>
                        {item?.isTitleForever === true && (
                          <div
                            className={`extend-rank__secondary extend-rank__secondary--${item?.titleForever?.toLowerCase()}`}
                            title="if you hit GOLD or DIAMOND for a certain number of weeks in a row, you get it forever"
                          >
                            <div
                              className={`extend-rank__secondary__icon extend-rank__secondary__icon--${
                                item?.isTitleForever === true
                                  ? "forever"
                                  : "undefined"
                              }`}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z"
                                  fill="#323232"
                                />
                              </svg>
                            </div>
                            <div className="extend-rank__secondary__label">
                              {_.startCase(_.toLower(item?.titleForever))}{" "}
                              Forever
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                </div>
              ),
              sort: {},
            },
            // {
            //   name: "Agency Count",
            //   key: "agencyCount",
            //   render: (item) => {
            //     return `${(+item.agencyCount).toLocaleString(getLocaleKey(), {
            //       maximumFractionDigits: 2,
            //       minimumFractionDigits: 2
            //     })}`;
            //   },
            //   sort: {},
            // },
            {
              name: "Transfer Main Wallet",
              key: "transferMainWallet",
              render: (item) => {
                return (
                  <div className="action">
                    <div
                      className="action__plus"
                      onClick={() =>
                        setOpenTransfer({
                          id: item?.id,
                          type: "plus",
                        })
                      }
                    >
                      <Icon.Plus />
                    </div>
                    {/* <div
                      className="action__minus"
                      onClick={() =>
                        setOpenTransfer({
                          email: item?.email,
                          type: "minus",
                        })
                      }
                    >
                      <Icon.Minus />
                    </div> */}
                  </div>
                );
              },
            },
          ]}
          fetchData={async (state) => {
            // AdminService.getFranchiseUserReport(100, user.userId, params).then(
            //   (res) => {
            //     setCurrentData(res);
            //     return res;
            //   }
            // )
            let params = { ...state };
            setParams(params);
            if (params["fromDate"])
              params["fromDate"] = new Date(params["fromDate"]);
            if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
            return AdminService.getListAffilateTable({
              ...params,
              pageSize: params.limit,
              page: params.pageNumber,
            }).then((res) => {
              setCurrentData(res);
              setDisableExport(!(res?.data?.length > 0))
              return res;
            });
          }}
        />
      )}
      {isShowPopup && (
        <SetLevelPopup
          dataUser={currentUserSetLevel}
          onClose={(value: any) => setIsShowPopup(value)}
          onIsShowTable={(value: any) => setIsShowTable(value)}
        />
      )}

      {openTransfer && (
        <PopupWraper
          center
          title={
            openTransfer?.type === "plus" ? "Receive Transfer" : "Send Transfer"
          }
          onClose={() => setOpenTransfer(null)}
        >
          <TransferMainWallet
            data={openTransfer}
            onFinish={() => {
              setForceUpdateTable(Math.random);
              setOpenTransfer(null);
            }}
          />
        </PopupWraper>
      )}
    </div>
  );
});

const SetLevelPopup: FC<any> = (props) => {
  const [categoryValue, setCategoryValue] = useState<any>();
  const [isResetLevel, setIsResetLevel] = useState<boolean>(false);

  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "level",
      },
    ],
    onSubmit: async (values) => {
      props?.onIsShowTable(false);
      return AdminService.setLevel(props?.dataUser?.userId, categoryValue)
        .then(() => {
          return CreateAlert({
            type: "success",
            message: "Set level successfully.",
          });
        })
        .catch(onError)
        .finally(() => {
          props?.onIsShowTable(true);
          props?.onClose(false);
        });
    },
  });

  const resetLevel = () => {
    props?.onIsShowTable(false);
    setIsResetLevel(true);
    return AdminService.resetLevel(props?.dataUser?.userId)
      .then(() => {
        return CreateAlert({
          type: "success",
          message: "Reset level successfully.",
        });
      })
      .finally(() => {
        props?.onIsShowTable(true);
        props?.onClose(false);
        setIsResetLevel(false);
      });
  };

  return (
    <PopupWraper title="Set level" onClose={() => props?.onClose(false)}>
      <div style={{ fontSize: "16px" }} className="container">
        <fieldset style={{ marginBottom: "32px" }}>
          <legend>Information:</legend>
          <div className="display-name">
            Display name:{" "}
            <span style={{ color: "#fff" }}>{props?.dataUser?.firstName}</span>
          </div>
          <div className="email">
            Email:{" "}
            <span style={{ color: "#fff" }}>{props?.dataUser?.email}</span>
          </div>
          <div className="level">
            Current level:{" "}
            <span style={{ color: "#fff" }}>{props?.dataUser?.rank}</span>
          </div>
        </fieldset>
        <InputWraper
          inputProps={getInputProps("level")}
          renderInput={(props) => (
            <InputSelect
              {...props}
              options={[
                {
                  label: "VIP1",
                  value: 1,
                },
                {
                  label: "VIP2",
                  value: 2,
                },
                {
                  label: "VIP3",
                  value: 3,
                },
                {
                  label: "VIP4",
                  value: 4,
                },
                {
                  label: "VIP5",
                  value: 5,
                },
                {
                  label: "VIP6",
                  value: 6,
                },
                {
                  label: "VIP7",
                  value: 7,
                },
              ]}
              onChange={(e) => setCategoryValue(e)}
              onTouched={() => false}
              value={categoryValue}
              name=""
            />
          )}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            className="mb10"
            onClick={handleSubmit}
            isLoading={isSubmitting}
            label="Set level"
            buttonType="success"
            type="submit"
          />

          <div style={{ cursor: "pointer" }} onClick={resetLevel}>
            <span>Reset level</span>
          </div>
        </div>
      </div>
    </PopupWraper>
  );
};

// export const SystemReportDetailPopup: FC<{
//     detail: any;
//     onClose: () => void;
// }> = (props) => {
//     if (!props.detail) return null;

//     const item = props.detail;

//     const data = [
//         {
//             label: "Start",
//             value: `${new Date(item.start).toLocaleDateString(getLocaleKey(), {
//                 hour12: false,
//             })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
//                 hour12: false,
//             })}`,
//         },
//         {
//             label: "End",
//             value: `${new Date(item.end).toLocaleDateString(getLocaleKey(), {
//                 hour12: false,
//             })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
//                 hour12: false,
//             })}`,
//         },
//         {
//             label: "Modified",
//             value: `${new Date(item.modified).toLocaleDateString(
//                 getLocaleKey(),
//                 {
//                     hour12: false,
//                 }
//             )} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
//                 hour12: false,
//             })}`,
//         },
//         {
//             label: "Total won/lose volume",
//             value: `${(+item.wonVolume).toLocaleString(getLocaleKey(), {
//                 maximumFractionDigits: 2,
//                 minimumFractionDigits: 2,
//             })}/${(+item.lostVolume).toLocaleString(getLocaleKey(), {
//                 maximumFractionDigits: 2,
//                 minimumFractionDigits: 2,
//             })}`,
//         },
//         {
//             label: "Hot Wallet",
//             value: (
//                 <span>
//                     USD:{" "}
//                     {(+item.hotWalletUsd).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 2,
//                         minimumFractionDigits: 2,
//                     })}{" "}
//                     <br />
//                     USDT:{" "}
//                     {(+item.hotWalletUsdt).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 8,
//                         minimumFractionDigits: 8,
//                     })}
//                 </span>
//             ),
//         },
//         {
//             label: "Deposit",
//             value: (
//                 <span>
//                     USD:{" "}
//                     {(+item.depositUsd).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 2,
//                         minimumFractionDigits: 2,
//                     })}{" "}
//                     <br />
//                     USDT:{" "}
//                     {(+item.depositUsdt).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 8,
//                         minimumFractionDigits: 8,
//                     })}
//                 </span>
//             ),
//         },
//         {
//             label: "Withdraw",
//             value: (
//                 <span>
//                     USD:{" "}
//                     {(+item.withdrawUsd).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 2,
//                         minimumFractionDigits: 2,
//                     })}{" "}
//                     <br />
//                     USDT:{" "}
//                     {(+item.withdrawUsdt).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 8,
//                         minimumFractionDigits: 8,
//                     })}
//                 </span>
//             ),
//         },
//         {
//             label: "User 's Balance",
//             value: (
//                 <span>
//                     USD:{" "}
//                     {(+item.userBalanceUsd).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 2,
//                         minimumFractionDigits: 2,
//                     })}{" "}
//                     <br />
//                     USDT:{" "}
//                     {(+item.userBalanceUsdt).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 8,
//                         minimumFractionDigits: 8,
//                     })}
//                 </span>
//             ),
//         },
//         {
//             label: "Safe",
//             value: (
//                 <span>
//                     USD:{" "}
//                     {(+item.safeUsd).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 2,
//                         minimumFractionDigits: 2,
//                     })}{" "}
//                     <br />
//                     USDT:{" "}
//                     {(+item.safeUsdt).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 8,
//                         minimumFractionDigits: 8,
//                     })}
//                 </span>
//             ),
//         },
//         {
//             label: "Login Count",
//             value: item.loginCount,
//         },
//         {
//             label: "Update VIP Commission",
//             value: item.directCommission,
//         },
//         {
//             label: "CashBack",
//             value: (+item.cashBack).toLocaleString(getLocaleKey(), {
//                 maximumFractionDigits: 2,
//                 minimumFractionDigits: 2,
//             }),
//         },
//         {
//             label: "New Package",
//             value: item.newPackage,
//         },
//         {
//             label: "Trading Commission",
//             value: item.rankCommission,
//         },
//     ];

//     return (
//         <div
//             className="SystemReport__DetailPopup"
//             id="SystemReport__DetailPopup"
//             onClick={(e: any) =>
//                 e.target.id === "SystemReport__DetailPopup"
//                     ? props.onClose()
//                     : null
//             }
//         >
//             <div className="box">
//                 <div className="boxTitle">
//                     <span>Detail Report</span>
//                     <div className="btnClose" onClick={() => props.onClose()}>
//                         <Icon.Close />
//                     </div>
//                 </div>
//                 <div className="content overload">
//                     <table>
//                         <tbody>
//                             {data.map((row, key) => (
//                                 <tr key={key}>
//                                     <td>{row.label}</td>
//                                     <td>{row.value}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// };
