import { GET_ROLE_PERMISSION_CONFIG, GET_ROLE_PERMISSION_CONFIG_ERROR } from "./rolePermission";
import { Store } from "redux";
import { CookieService, ECookieVariable } from "../cookie";
import { RequestMainService } from "../request";
import { GET_MAINTENANCE_CONFIG, GET_MAINTENANCE_CONFIG_ERROR } from "./maintenance.reduce";
import { GET_WITHDRAW_CONFIG, GET_WITHDRAW_CONFIG_ERROR } from "./withdraw.reduce";
import {
  GET_RANK_CONFIG_WITHDRAW,
  GET_RANK_CONFIG_WITHDRAW_ERROR,
} from "./rankConfigWithdraw.reduce";
import { store } from "../../store";
import { CreateAlert, onError } from "../../modules";
import { GET_MAINTENANCE_JOB_SERVICE, GET_MAINTENANCE_JOB_SERVICE_ERROR } from "./maintenanceJobService.reduce";

export class ConfigService {
  static async getServerConfig(store: Store) {
    const token = CookieService.get(ECookieVariable.USER_ACCESS_TOKEN);
    if (token)
      return RequestMainService.get(`/admin/get-server-config`)
        .then(({ result }) => {
          const data = result.find((v: any) => v.serverConfigName === "WITHDRAW_LIMIT_CONFIG");
          const { USER_MAXIMUM_IN_DAY
            , SYSTEM_MAXIMUM_IN_DAY
            , USER_MAXIMUM_IN_DAY_COUNT
            , MIN_WITHDRAW
            , MAXIMUN_AUTO_WITHDRAW_USD
            , ALLOW_WITHDRAW_TIME_FROM
            , ALLOW_WITHDRAW_TIME_TO
          } = JSON.parse(data.value);
          store.dispatch({
            type: GET_WITHDRAW_CONFIG,
            data: {
              USER_MAXIMUM_IN_DAY
              , SYSTEM_MAXIMUM_IN_DAY
              , USER_MAXIMUM_IN_DAY_COUNT
              , MIN_WITHDRAW
              , MAXIMUN_AUTO_WITHDRAW_USD
              , ALLOW_WITHDRAW_TIME_FROM
              , ALLOW_WITHDRAW_TIME_TO
            },
          });
        })
        .catch((res) =>
          store.dispatch({
            type: GET_WITHDRAW_CONFIG_ERROR,
            error: res.error,
          })
        );
  }

  static async setConfig(payload: any) {
    return RequestMainService.post(`/admin/server-config`, payload)
      .then(() => {
        CreateAlert({ type: "success", message: "Set config successful." });
        this.getServerConfig(store);
      })
      .catch(onError);
  }

  static async getServerConfigMaintenance(store: Store) {
    const token = CookieService.get(ECookieVariable.USER_ACCESS_TOKEN);
    if (token)
      return RequestMainService.get(`/admin/get-maintenance-server`)
        .then(({ result }) => {
          store.dispatch({ type: GET_MAINTENANCE_CONFIG, data: result });
        })
        .catch((res) =>
          store.dispatch({
            type: GET_MAINTENANCE_CONFIG_ERROR,
            error: res.error,
          })
        );
  }

  static async switchMaintenanceServer(enable: any) {
    return RequestMainService.post(`/admin/switch-maintenance-server`, enable);
  }

  static async getServerConfigRolePermission(store: Store) {
    const token = CookieService.get(ECookieVariable.USER_ACCESS_TOKEN);
    if (token)
      return RequestMainService.get(`/admin/role-permission`)
        .then((res) => {
          store.dispatch({
            type: GET_ROLE_PERMISSION_CONFIG,
            data: res.result,
          });
        })
        .catch((err) => {
          store.dispatch({
            type: GET_ROLE_PERMISSION_CONFIG_ERROR,
            error: err.error,
          });
        });
  }

  static async updateRolePermissionServer(id: number, status: boolean) {
    return RequestMainService.put(`/admin/role-permission/${id}/${status}`);
  }

  static async ChangePassword(payload: any) {
    return RequestMainService.put(`/admin/change-password`, payload);
  }

  static async getActivitiesSetting(payload: any) {
    return RequestMainService.get(`/admin/user-activity`, payload).then((res) => ({
      count: res.result.total,
      data: res.result.data,
    }));
  }

  static async getActivitiesAdmin(payload: any) {
    return RequestMainService.get(`/admin/api-activity`, payload).then((res) => ({
      count: res?.result?.total,
      data: res?.result?.data,
    }));
  }

  static getListActionActivity() {
    return [
      { label: "VIEW", value: "VIEW" },
      { label: "EDIT", value: "EDIT" },
      { label: "CREATE", value: "CREATE" },
      { label: "DELETE", value: "DELETE" },
      { label: "APPROVE", value: "APPROVE" },
      { label: "REJECT", value: "REJECT" },
      { label: "LOGIN", value: "LOGIN" },
      { label: "LOGOUT", value: "LOGOUT" },
    ];
  }

  static async getListRankConfigWithdraw(store: Store) {
    return RequestMainService.get("/admin/rank-config")
      .then(({ result }) => {
        store.dispatch({
          type: GET_RANK_CONFIG_WITHDRAW,
          data: result?.data,
        });
      })
      .catch((res) =>
        store.dispatch({
          type: GET_RANK_CONFIG_WITHDRAW_ERROR,
          error: res.error,
        })
      );
  }

  static async updateListRankConfigWithdraw(data: any) {
    return RequestMainService.post("/admin/update-rank-config", data);
  }

  static async getListPoolStreak() {
    return RequestMainService.get("/admin/pool-streaks").then((res) => ({
      count: res.result.total,
      data: res.result.data.sort((a: any, b: any) => a.poolStreakId - b.poolStreakId),
    }));
  }

  static async getListAnnouncement() {
    return RequestMainService.get("/admin/announcement").then((res) => ({
      count: res.result.total,
      data: res.result.data.sort((a: any, b: any) => a.poolStreakId - b.poolStreakId),
    }));
  }

  static async editPoolStreak(id: number, payload: any) {
    return RequestMainService.put(`/admin/pool-streaks/${id}`, payload);
  }

  static async editAnnouncement(id: number, payload: any) {
    return RequestMainService.put(`/admin/announcement/${id}`, payload);
  }

  static async createPoolStreak(payload: any) {
    return RequestMainService.post("/admin/pool-streaks", payload);
  }

  static async createAnnouncement(payload: any) {
    return RequestMainService.post("/admin/announcement", payload);
  }

  static async deletePoolStreak(id: number) {
    return RequestMainService.delete(`/admin/pool-streaks/${id}`)
      .then(() => {
        CreateAlert({
          message: "Delete success.",
          type: "success",
        });
      })
      .catch((error: any) => {
        CreateAlert({
          message: error.message,
          type: "danger",
        });
      });
  }

  static async updateTokenBonusConfig(payload: any) {
    return RequestMainService.put("/admin/token-bonus-config", payload);
  }

  static async getTokenBonusConfig() {
    return RequestMainService.get("/admin/token-bonus-config");
  }

  static async getJobServiceConfigMaintenance(store: Store) {
    const token = CookieService.get(ECookieVariable.USER_ACCESS_TOKEN);
    if (token)
      return RequestMainService.get(`/job/maintenance`)
        .then(({ result }) => {
          store.dispatch({ type: GET_MAINTENANCE_JOB_SERVICE, data: result });
        })
        .catch((res) =>
          store.dispatch({
            type: GET_MAINTENANCE_JOB_SERVICE_ERROR,
            error: res.error,
          })
        );
  }

  static async swithMaintenanceJobService(flag: any) {
    return RequestMainService.put(`/job/maintenance/${flag}`);
  }

  static async getServerConfigByName(serverConfigName: string, isGetAll: boolean = false) {
    return RequestMainService.get(`/admin/get-server-config`).then((res) => {
      const data = res?.result?.find((v: any) => v.serverConfigName === serverConfigName);
      if (isGetAll) return data;
      return JSON.parse(data.value);
    })
  }

}
