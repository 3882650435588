import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { InputText } from "../../../components";
import { translate } from "../../../languages";
import {
  Button,
  CreateAlert,
  Icon,
  InputWraper,
  useForm,
} from "../../../modules";
import { ConfigService } from "../../../services/config/config.service";
import { withConfigWraper } from "../wraper";
import { GeneralPopupCompnt } from "../../../components/general-popup-compnt";

const PurchaseLimitConfigPage: FC<any> = withConfigWraper(() => {
  const [purchaseLimit, setPurchaseLimit] = useState<any>();
  const [isDisableInput1, setIsDisableInput1] = useState<boolean>(true);
  const [dataPopup, setDataPopup] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleSubmit, getInputProps } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "purchaseLimit",
        defaultValue: purchaseLimit ?? "N/A",
        validate: Yup.number()
          .typeError(translate("must be a number"))
          .required(translate("must be provided"))
          .positive("must be greater than 0")
          .integer("must be an integer"),
        isDisabled: isDisableInput1,
      },
    ],
    onSubmit: async (values) => {
      let payload = {
        purchaseLimit: +values?.purchaseLimit,
      };

      setDataPopup(payload);
    },
  });

  let handleOnClickConfirmPopup = () => {
    if (isLoading === true) return;
    setIsDisableInput1(true);
    setIsLoading(true);

    const payload = {
      isActiveScan: true,
      shouldProcessWithdraw: false,
      value: JSON.stringify({ PURCHASE_LIMIT: +dataPopup?.purchaseLimit }),
      serverConfigName: "PURCHASE_LIMIT_CONFIG",
    };

    return ConfigService.setConfig(payload)
      .then(async () => {
        ConfigService.getServerConfigByName("PURCHASE_LIMIT_CONFIG").then(
          (res) => {
            setPurchaseLimit(res?.PURCHASE_LIMIT);
          }
        );
      })
      .catch((err) => {
        CreateAlert({
          type: "danger",
          message: translate(err?.message),
        });
      })
      .finally(() => {
        setDataPopup(null);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    ConfigService.getServerConfigByName("PURCHASE_LIMIT_CONFIG").then(
      (res: any) => {
        setPurchaseLimit(res?.PURCHASE_LIMIT);
      }
    );
  }, []);

  return (
    <div className="row mb20 vending-machine-config-page">
      <div className="col-sm-5">
        <form className="blockLists" onSubmit={handleSubmit}>
          <InputWraper
            label={"Purchase Limit"}
            inputProps={getInputProps("purchaseLimit")}
            renderInput={(props) => (
              <>
                <InputText {...props} />
                <div
                  className="icon"
                  onClick={() => setIsDisableInput1((state) => !state)}
                >
                  <Icon.ActionEditIcon />
                </div>
              </>
            )}
          />
          <div>
            <Button
              label="Submit"
              isMiddle
              buttonType="success"
              type="submit"
              disabled={
                purchaseLimit == null ||
                isDisableInput1 ||
                dataPopup != null ||
                purchaseLimit === +getInputProps("purchaseLimit").value
              }
            />
          </div>
        </form>
      </div>
      {dataPopup ? (
        <GeneralPopupCompnt
          onClose={() => {
            setDataPopup(null);
          }}
          onClickConfirm={() => handleOnClickConfirmPopup()}
          textButton="OK"
          titlePopup={"WARNING!"}
          messagePopup={[
            <>
              {purchaseLimit != dataPopup?.purchaseLimit && (
                <>
                  <div style={{ textAlign: "start", marginBottom: "8px" }}>
                    <div className="label">Purchase limit:</div>
                    <div className="content">
                      <div className="content1">
                        - OLD:{" "}
                        <span style={{ color: "#ff4c51" }}>
                          {purchaseLimit}
                        </span>
                      </div>
                      <div className="content1">
                        - NEW:{" "}
                        <span style={{ color: "#56ca00" }}>
                          {dataPopup?.purchaseLimit}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {purchaseLimit == dataPopup?.purchaseLimit && "No change"}
            </>,
          ]}
        />
      ) : null}
    </div>
  );
});

export default PurchaseLimitConfigPage;
