import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { InputText } from "../../../components";
import { translate } from "../../../languages";
import {
  Button,
  CreateAlert,
  Icon,
  InputWraper,
  useForm,
} from "../../../modules";
import { ConfigService } from "../../../services/config/config.service";
import { withConfigWraper } from "../wraper";
import { GeneralPopupCompnt } from "../../../components/general-popup-compnt";

const CommissionConfig: FC<any> = withConfigWraper(() => {
  const [commission, setcommission] = useState<any>();
  const [isDisableInput1, setIsDisableInput1] = useState<boolean>(true);
  const [dataPopup, setDataPopup] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleSubmit, getInputProps } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "commission",
        defaultValue: commission ?? "N/A",
        validate: Yup.number()
          .typeError(translate("must be a number"))
          .required(translate("must be provided"))
          .positive("must be greater than 0"),
        isDisabled: isDisableInput1,
      },
    ],
    onSubmit: async (values) => {
      let payload = {
        commission: +values?.commission,
      };

      setDataPopup(payload);
    },
  });

  let handleOnClickConfirmPopup = () => {
    if (isLoading === true) return;
    setIsDisableInput1(true);
    setIsLoading(true);

    const payload = {
      isActiveScan: true,
      shouldProcessWithdraw: false,
      value: JSON.stringify({
        INVESTMENT_TRADING_COMMISSION: +dataPopup?.commission,
      }),
      serverConfigName: "COMMISSION_CONFIG",
    };

    return ConfigService.setConfig(payload)
      .then(async () => {
        ConfigService.getServerConfigByName("COMMISSION_CONFIG").then((res) => {
          setcommission(res?.INVESTMENT_TRADING_COMMISSION);
        });
      })
      .catch((err) => {
        CreateAlert({
          type: "danger",
          message: translate(err?.message),
        });
      })
      .finally(() => {
        setDataPopup(null);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    ConfigService.getServerConfigByName("COMMISSION_CONFIG").then(
      (res: any) => {
        console.log("res", res);

        setcommission(res?.INVESTMENT_TRADING_COMMISSION);
      }
    );
  }, []);

  return (
    <div className="row mb20 vending-machine-config-page">
      <div className="col-sm-5">
        <form className="blockLists" onSubmit={handleSubmit}>
          <InputWraper
            label={"Comission"}
            inputProps={getInputProps("commission")}
            renderInput={(props) => (
              <>
                <InputText {...props} />
                <div
                  className="icon"
                  onClick={() => setIsDisableInput1((state) => !state)}
                >
                  <Icon.ActionEditIcon />
                </div>
              </>
            )}
          />
          <div>
            <Button
              label="Submit"
              isMiddle
              buttonType="success"
              type="submit"
              disabled={
                commission == null ||
                isDisableInput1 ||
                dataPopup != null ||
                commission === +getInputProps("commission").value
              }
            />
          </div>
        </form>
      </div>
      {dataPopup ? (
        <GeneralPopupCompnt
          onClose={() => {
            setDataPopup(null);
          }}
          onClickConfirm={() => handleOnClickConfirmPopup()}
          textButton="OK"
          titlePopup={"WARNING!"}
          messagePopup={[
            <>
              {commission != dataPopup?.commission && (
                <>
                  <div style={{ textAlign: "start", marginBottom: "8px" }}>
                    <div className="label">Purchase limit:</div>
                    <div className="content">
                      <div className="content1">
                        - OLD:{" "}
                        <span style={{ color: "#ff4c51" }}>{commission}</span>
                      </div>
                      <div className="content1">
                        - NEW:{" "}
                        <span style={{ color: "#56ca00" }}>
                          {dataPopup?.commission}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {commission == dataPopup?.commission && "No change"}
            </>,
          ]}
        />
      ) : null}
    </div>
  );
});

export default CommissionConfig;
